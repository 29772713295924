import React, { useEffect, useRef } from "react";
import bg_contacto1 from "../assets/CONTACTO/CONTACTO-BANNER-1.webp";
import pdfURL from "../assets/DOCUMENTOS/SISTEC.pdf";
import { Link } from "react-router-dom";

const AltaSistec = () => {
  const isFirstRender = useRef(false);
  useEffect(() => {
    if (!isFirstRender.current) {
      window.open(pdfURL, "_blank");
      isFirstRender.current = true;
    }
  }, []);

  return (
    <div
      style={{
        backgroundImage: "url('" + bg_contacto1 + "')",
        backgroundSize: "cover",
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
      }}
    >
      <h2>Abriendo PDF... </h2>
      <p>
        Si el PDF no se abre de manera automática.{" "}
        <Link to={pdfURL} style={{ fontSize: 20 }} rel="noopener noreferrer">
          Haz click aquí
        </Link>
      </p>
    </div>
  );
};

export default AltaSistec;
