import { Col, Flex, Row } from "antd";
import React from "react";
import bg_contacto1 from "../assets/CONTACTO/CONTACTO-BANNER-1.webp";
import { FilePdfOutlined } from "@ant-design/icons";

// Documentos
import aviso_privacidad from "../assets/DOCUMENTOS/AVISO_DE_PRIVACIDAD_INTEGRAL.pdf";
import poliza_cajeros from "../assets/DOCUMENTOS/POLIZA_DE_GARANTIA_PARA_CAJERO_QUICKPAY.pdf";
import reservas_limitaciones from "../assets/DOCUMENTOS/Reservas_limitaciones_en_formato_de_cotizacion.pdf";

const styles = {
  fontFamily: "Avenir",
  letterSpacing: 6,
  fontSize: "xx-large",
  margin: 10,
};

const documentos = [
  {
    nombre: "aviso",
    url: aviso_privacidad,
    descripcion: "Aviso de privacidad integral",
  },
  // {
  //   nombre: "reservas",
  //   url: reservas_limitaciones,
  //   descripcion: "Reservas y limitaciones en formato de Cotización",
  // },
];

const Avisos = () => {
  return (
    <div
      style={{
        backgroundImage: "url('" + bg_contacto1 + "')",
        backgroundSize: "cover",
        width: "100%",
        height: "100vh",
      }}
    >
      <Row
        justify={"center"}
        align={"middle"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col
          style={{
            marginTop: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Row justify={"center"}>
            {documentos.map((d) => (
              <Col style={{ margin: 20 }}>
                <FilePdfOutlined style={{ fontSize: 50}}/>
                <a href={d.url} target="_blank" style={styles} key={d.nombre}>
                  {d.descripcion}
                </a>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Avisos;
