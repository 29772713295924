import { Col, Flex, Row } from "antd";
import React from "react";
import bg_contacto1 from "../assets/CONTACTO/CONTACTO-BANNER-1.webp";

// Documentos
import poliza_cajeros from "../assets/DOCUMENTOS/POLIZA_DE_GARANTIA_PARA_CAJERO_QUICKPAY.pdf";
import reservas_limitaciones from "../assets/DOCUMENTOS/Reservas_limitaciones_en_formato_de_cotizacion.pdf";

const styles = {
  fontFamily: "Avenir",
  letterSpacing: 6,
  fontSize: "xx-large",
};

const Garantias = () => {
  return (
    <div
      style={{
        backgroundImage: "url('" + bg_contacto1 + "')",
        backgroundSize: "cover",
        width: "100%",
        height: "100vh",
      }}
    >
      <Row
        justify={"center"}
        align={"middle"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col
          style={{
            marginTop: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Row justify={"center"}>
            <Col >
              <a href={poliza_cajeros} target="_blank" style={styles}>
                Poliza de Garantiza Cajero QUICKPAY
              </a>
            </Col>
            {/* <Col  style={{ margin: 20 }}>
              <a href={reservas_limitaciones} target="_blank" style={styles}>
                Reservas y limitaciones en formato de Cotización
              </a>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Garantias;
