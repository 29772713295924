import "./App.css";
import { Home } from "./components/Home";
import Avisos from "./components/Avisos";
import Garantias from "./components/Garantias";
import AltaSistec from "./components/AltaSistec";

// import Avisos from "./components/Avisos";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/altasistec" element={<AltaSistec />} />
        <Route path="/avisosprivacidad" element={<Avisos />} />
        <Route path="/garantias" element={<Garantias />} />
      </Routes>
    </Router>
  );
}

export default App;
