import React, { useRef, useState } from "react";
import { Layout, Menu, Row, Col, Button, Select, Form } from "antd";
import Logo from "../assets/TOP/LOGO-EMPÁTICA.webp";
import MenuItem from "antd/es/menu/MenuItem";
import Slider from "react-slick";
// import "../styles/home.styles.css";
import "../styles/new.home.styles.css";
import { ConfigProvider, Modal } from "antd";
import { createStyles } from "antd-style";
import { CloseOutlined } from "@ant-design/icons";
import {
  IconChevronCompactLeft,
  IconChevronCompactRight,
} from "@tabler/icons-react";
import AnchorLink from "react-anchor-link-smooth-scroll";

// Elementos gráficos
import video_presentation1 from "../assets/VIDEO/QPMini_Empatica_LQ.webm";
import video_presentation2 from "../assets/VIDEO/Video_landing_page.webm";
import video_presentation3 from "../assets/VIDEO/Video_landing_page.mp4";

// Logos Empresas
import capalac from "../assets/BANNER_ALIANZAS/CALAPAC.webp";
import comapa from "../assets/BANNER_ALIANZAS/COMAPA.webp";
import cmapa from "../assets/BANNER_ALIANZAS/CMAPA.webp";
import jlapas from "../assets/BANNER_ALIANZAS/JALAPAS.webp";
import japac from "../assets/BANNER_ALIANZAS/JAPAC.webp";
import japasp from "../assets/BANNER_ALIANZAS/JAPASP.webp";
import jmapa from "../assets/BANNER_ALIANZAS/JMAPA.webp";
import jumapaa from "../assets/BANNER_ALIANZAS/JUMAPAA.webp";
import ooapas from "../assets/BANNER_ALIANZAS/OOAPAS.webp";
import sapa from "../assets/BANNER_ALIANZAS/SAPA.webp";
import sapasma from "../assets/BANNER_ALIANZAS/SAPASMA.webp";
import siamm from "../assets/BANNER_ALIANZAS/SIAMM.webp";
import simapas from "../assets/BANNER_ALIANZAS/SIMAPAS.webp";
import smapau from "../assets/BANNER_ALIANZAS/SMAPAU.webp";
import bg_contacto1 from "../assets/CONTACTO/CONTACTO-BANNER-1.webp";
import bg_contacto2 from "../assets/CONTACTO/CONTACTO-BANNER-2.webp";
import logo_phone from "../assets/CONTACTO/TELÉFONO.webp";
import logo_mail from "../assets/CONTACTO/MENSAJE.webp";
import logo_agua from "../assets/INFO_EMPÁTICA/ÍCONO-1.webp";
import logo_circuito from "../assets/INFO_EMPÁTICA/ÍCONO-2.webp";
import logo_tiempo from "../assets/INFO_EMPÁTICA/ÍCONO-3.webp";
import logo_escudo from "../assets/INFO_EMPÁTICA/ÍCONO-4.webp";
import logo_e from "../assets/INFO_EMPÁTICA/ÍCONO-E.webp";
import bg_productos_varios from "../assets/PRODUCTOS/FONDO-PRODUCTOS.webp";
import LogoCorpasi from "../assets/LOGO_CORPASI/CORPASI.png";
// imagenes Bot whats
import bg_whats from "../assets/PRODUCTOS/BOT_DE_WHATSAPP/Bot.webp";
import logo_whats from "../assets/PRODUCTOS/BOT_DE_WHATSAPP/WA LOGO.webp";

// imagenes cajero
import bg_cajeros from "../assets/PRODUCTOS/CAJEROS/Cajeros.webp";
import bg_301 from "../assets/PRODUCTOS/CAJEROS/QuickPay301.webp";
import bg_101 from "../assets/PRODUCTOS/CAJEROS/QuickPay101.webp";
import bg_quickmini from "../assets/PRODUCTOS/CAJEROS/QuickPayMini.webp";
import logo_aps from "../assets/PRODUCTOS/CAJEROS/APS_LOGO.webp";

// imagenes paga_tu_agua
import bg_pta from "../assets/PRODUCTOS/PAGA_TU_AGUA/PTA.webp";
import logo_pta from "../assets/PRODUCTOS/PAGA_TU_AGUA/Logo_PTA_Blanco.webp";

// imagenes sigem
import bg_sigem from "../assets/PRODUCTOS/SIGEM/Kiosko.webp";
import logo_sigem from "../assets/PRODUCTOS/SIGEM/SIGEM.webp";

// imagenes siscom
import bg_siscom from "../assets/PRODUCTOS/SISCOM/SISCOM.webp";
import logo_siscom from "../assets/PRODUCTOS/SISCOM/SISCOM_LOGO.webp";

// imagenes toma de lectura

// imagenes turnero
import bg_turnero from "../assets/PRODUCTOS/TURNERO/Turnero.webp";

// imagenes lecturas
import bg_lecturas from "../assets/PRODUCTOS/TOMA_DE_LECTURA/Toma_lectura.webp";

// PDF avisos de privacidad
import privacidad_cajeros from "../assets/PRODUCTOS/CAJEROS/AVISOS/Brochure_QPMini_CTA.pdf";
import poliza_cajeros from "../assets/DOCUMENTOS/POLIZA_DE_GARANTIA_PARA_CAJERO_QUICKPAY.pdf";
import reservas_limitaciones from "../assets/DOCUMENTOS/Reservas_limitaciones_en_formato_de_cotizacion.pdf";
import { Link } from "react-router-dom";

const { Header, Content } = Layout;

const productos = [
  {
    nombre: "BOT DE WHATSAPP",
    foto: bg_whats,
    titulo: "Chatbot de atención 24/7",
    informacion:
      "Que atiende dudas y reclamos sobre realizar el pago de agua sin recibo, realizar una queja o comenzar a darle seguimiento a alguna problemática que se presente, como fugas, falta de agua, etc...",
    logo: logo_whats,
    sub_productos: null,
  },
  {
    nombre: "CAJEROS",
    foto: bg_cajeros,
    informacion: null,
    logo: logo_aps,
    aviso_privacidad: privacidad_cajeros,
    sub_productos: [
      {
        nombre: "CAJERO APS 101",
        foto: bg_101,
        informacion: `Quickpay101 fue diseñado tomando en cuenta las necesidades del Sector Comercial, desde ventas de mostrador hasta tiendas departamentales y cadenas de autoservicio.

        Recibe billetes y monedas. Mediante su sistema de reciclado utiliza el mismo dinero que recibe para entregar cambio. Procesa pagos con tarjetas de crédito VISA, MasterCard, CARNET y AMEX (opcional).
        
        -Certificado para operar en el mercado mexicano.
        
        -El equipo puede ser ordenado con un "Sidecar", el cuál puede ser configurado con diferentes opciones dependiendo de la necesidad incluyendo antenas para lectura de etiquetas RFID, báscula y portabolsas.`,
        logo: "",
        titulo: "QUICKPAY 101",
      },
      {
        nombre: "CAJERO APS 301",
        foto: bg_301,
        informacion: `Quickpay301 fue diseñado tomando en cuenta las necesidades del sector financiero lo cuál lo hace un equipo muy confiable y seguro.

        Recibe billetes y monedas. Mediante su sistema de reciclado utiliza el mismo dinero que recibe para entregar cambio. Tiene la capacidad de recibir y entregar fajos de hasta 50 billetes por lo que es ideal para transacciones de alto volumen.
        Procesa pagos con tarjetas de crédito VISA, MasterCard, CARNET y AMEX (Opcional).
        
        -Certificado para operar en el mercado mexicano.
        
        -Cuenta con sensores de alarma tanto en las puertas como en la bóveda para detectar intrusiones.
        
        -Se comunica por lnternet a través de la red GSM enviando notificaciones de estado en tiempo real.`,
        logo: "",
        titulo: "Multifuncional",
      },
      {
        nombre: "QUICKPAY MINI",
        foto: bg_quickmini,
        titulo: "QUICKPAY MINI",
        informacion: `Quickpaymini fue diseñado tomando en cuenta las necesidades de los comercios minoristas que desean eliminar el manejo de dinero en sus empleados.

        Recibe billetes y monedas. Mediante su sistema de reciclado utiliza el mismo dinero que recibe para entregar cambio. Procesa pagos con tarjetas de crédito VISA y MasterCard. 
        
        
        - Certificación para operar en el mercado mexicano. 
        
        - Cuenta con Tablet desde donde se realizan todas las operaciones.
        
        - Controlado desde cualquier dispositivo móvil o computadora de escritorio con conexión WiFi.
        
        - Para cualquier punto de venta (POS) sin necesidad de desarrollos de software especiales.`,
        logo: "",
      },
    ],
  },
  {
    nombre: "PAGATUAGUA",
    foto: bg_pta,
    informacion: `Es una aplicación web y móvil que le permite al usuario
      realizar el pago de su servicio de agua potable de forma
      rápida y segura desde su celular, o cualquier dispositivo
      móvil, en cualquier momento y cualquier lugar.`,
    logo: logo_pta,
    titulo: "PAGATUAGUA",
    sub_productos: null,
  },
  {
    nombre: "SIGEM",
    foto: bg_sigem,
    informacion: `Creamos un sistema que gestiona el flujo de ciudadanosque
    realizan un trámite,ingresano solicitan información en una
    dependencia, oficina o parque público.
    Administrando evaluaciones de atención,comentarios y
    métricas de desempeño.`,
    logo: logo_sigem,
    sub_productos: null,
  },
  {
    nombre: "SISCOM",
    foto: bg_siscom,
    informacion: `Sistema comercial para el control de la operación de las
    áreas dentro de un Organismo Operador, con información
    precisa y confiable para la toma de decisiones
    comerciales.`,
    logo: logo_siscom,
    sub_productos: null,
  },
  {
    nombre: "TURNERO",
    foto: bg_turnero,
    informacion: `Sistema de control de tiempos para la gestión de procesos
    en el servicio a clientes. Creamos un sistema que mejora
    la experiencia de servicio de tus clientes. Lo hicimos
    pensando en optimizar las funciones de tu equipo de
    trabajo.`,
    logo: logo_e,
    sub_productos: null,
  },
  {
    nombre: "APP DE LECTURAS",
    foto: bg_lecturas,
    informacion: "",
    logo: logo_e,
    sub_productos: null,
  },
  /*  {
    nombre: "",
    foto: "",
    informacion: "",
    logo: "",
    sub_productos: [
      {
        nombre: "",
        foto: "",
        informacion: "",
        logo: "",
      },
    ],
  }, */
];

const useStyle = createStyles(({ token }) => ({
  "my-modal-body": {
    background: token["blue-1"],
    padding: token.paddingSM,
  },
  "my-modal-mask": {
    boxShadow: `inset 0 0 15px #fff`,
  },
  "my-modal-header": {
    borderBottom: `1px dotted ${token.colorPrimary}`,
  },
  "my-modal-footer": {
    color: token.colorPrimary,
  },
  "my-modal-content": {
    border: "1px solid #333",
  },
}));

export const Home = () => {
  const [currentProduct, setCurrentProduct] = useState(undefined);
  const [modalInfo, setModalInfo] = useState(false);
  const [transaction, setTransaction] = useState("2,000,000");
  const [status, setStatus] = useState("stopped");
  const { styles } = useStyle();
  const slider = useRef();

  const classNames = {
    body: styles["my-modal-body"],
    mask: styles["my-modal-mask"],
    header: styles["my-modal-header"],
    footer: styles["my-modal-footer"],
    content: styles["my-modal-content"],
  };

  const modalStyles = {
    header: {
      // borderLeft: `5px solid ${token.colorPrimary}`,
      borderRadius: 0,
      paddingInlineStart: 5,
    },
    body: {
      boxShadow: "inset 0 0 5px #999",
      borderRadius: 30,
      backgroundImage: "url('" + bg_productos_varios + "')",
      backgroundSize: "cover",
    },
    mask: {
      backdropFilter: "blur(10px)",
    },
    footer: {
      borderTop: "1px solid #333",
    },
    content: {
      // boxShadow: "0 0 30px #999",
      backgroundColor: "transparent",
      border: "transparent",
      borderRadius: 0,
      boxShadow: 0,
    },
  };

  const verMasInfo = (producto) => {
    setCurrentProduct(producto);
    setModalInfo(true);
  };

  const next = () => slider.current.slickNext();
  const previous = () => slider.current.slickPrev();
  const [vidIdx, setVidIdx] = useState(0);
  const [currentVideo, setCurrentVideo] = useState(0);

  const videos = [
    { src: video_presentation1, title: "Video 1" },
    { src: video_presentation2, title: "Video 1" },
    { src: video_presentation3, title: "Video 1" },
  ];

  const handleVideoEnd = () => {
    console.log("Actual video: ", videos[vidIdx].title);
    if (currentVideo < videos.length - 1) {
      setCurrentVideo(currentVideo + 1);
    } else {
      setCurrentVideo(0);
    }
  };

  const ChangePolizas = (e) => {
    const pdf = e;
    window.open(pdf, "_blank");
  };

  const [form] = Form.useForm();

  return (
    <Layout>
      {/* 
        Menu Empatica soluciones
      */}
      <Header className="page_header">
        <Row align={"middle"}>
          <Col
            xs={20}
            md={4}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              aria-label="imagen_corpasi_logo"
              src={LogoCorpasi}
              alt="corpasi_logo"
              style={{ width: 200, height: "auto" }}
            />
          </Col>
          <Col
            xs={20}
            md={4}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              aria-label="imagen_empatica_logo"
              src={Logo}
              alt="empatica_logo"
              style={{ width: 200, height: "auto" }}
            />
          </Col>
          <Col xs={4} md={16} lg={16}>
            <Menu mode="horizontal" theme="dark" className="menu_empatica">
              <AnchorLink
                href="#alianzas"
                aria-label="enlace_alianzas"
                role="link"
              >
                <MenuItem key="alianzas_link" style={{ color: "#FFFFFF" }}>
                  ALIANZAS
                </MenuItem>
              </AnchorLink>
              <AnchorLink
                href="#productos"
                aria-label="enlace_productos"
                role="link"
              >
                <MenuItem key="productos_link" style={{ color: "#FFFFFF" }}>
                  PRODUCTOS
                </MenuItem>
              </AnchorLink>
              <AnchorLink
                href="#contacto"
                aria-label="enlace_contacto"
                role="link"
              >
                <MenuItem key="contacto_link" style={{ color: "#FFFFFF" }}>
                  CONTACTO
                </MenuItem>
              </AnchorLink>
            </Menu>
          </Col>
        </Row>
      </Header>
      <Content
      //</Layout>style={contentStyle}
      >
        {/* 
          Contenedor de Vídeo
        */}
        {/* <div
          style={{
            width: "100%",
          }}
        > */}
        <video
          className="video-player"
          autoPlay={true}
          onEnded={handleVideoEnd}
          style={{ width: "100%", height: "auto" }}
          playsInline={true}
          preload="meta"
          src={videos[currentVideo].src}
          controls
          muted
        >
          <source src={videos[currentVideo].src} type="video/webm" />
        </video>
        {/* </div> */}

        {/*  
          Contenedor de Alianzas
        */}
        <div id="alianzas" className="alianzas_container">
          <Row justify={"center"} align={"middle"}>
            <Col
              // xs={24}
              // md={24}
              // lg={24}
              // sm={24}
              style={{
                color: "#FFFFFF",
                fontFamily: "Avenir",
                letterSpacing: 6,
                fontSize: "xx-large",
              }}
            >
              ALIANZAS
            </Col>
            <Col>
              <Row justify={"center"}>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    aria-label="imagen_logo_capalac"
                    className="img_logo_alianza"
                    src={capalac}
                    alt="capalac_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    aria-label="imagen_logo_cmapa"
                    className="img_logo_alianza"
                    src={cmapa}
                    alt="cmapa_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={jlapas}
                    alt="jlapas_logo"
                    aria-label="imagen_logo_jlapas"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={siamm}
                    aria-label="imagen_logo_siamm"
                    alt="siamm_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={jumapaa}
                    aria-label="imagen_logo_jumapaa"
                    alt="jumapaa_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={smapau}
                    alt="smapau_logo"
                    aria-label="imagen_logo_smapau"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    aria-label="imagen_logo_alianza"
                    src={japasp}
                    alt="japasp_logo"
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row justify={"center"}>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={comapa}
                    aria-label="imagen_logo_comapa"
                    alt="comapa_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={ooapas}
                    aria-label="imagen_logo_ooapas"
                    alt="ooapas_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={simapas}
                    aria-label="imagen_logo_simapas"
                    alt="simapas_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={sapasma}
                    aria-label="imagen_logo_sapasma"
                    alt="sapasma_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={sapa}
                    aria-label="imagen_logo_sapa"
                    alt="sapa_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={japac}
                    aria-label="imagen_logo_japac"
                    alt="japac_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    className="img_logo_alianza"
                    src={jmapa}
                    aria-label="imagen_logo_jmapa"
                    alt="jmapa_logo"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* 
          Alianzas mobile
        */}
        <div id="alianzas" className="alianzas_container_mobile">
          <Row
            justify={"center"}
            style={{
              paddingTop: 50,
              position: "relative",
            }}
            align={"middle"}
          >
            <Col
              xs={24}
              md={24}
              lg={24}
              style={{
                color: "#FFFFFF",
                fontFamily: "Avenir",
                letterSpacing: 6,
                fontSize: "xx-large",
              }}
            >
              ALIANZAS
            </Col>
            <Col>
              <Row justify={"center"}>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_capalac"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={capalac}
                    alt="capalac_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    aria-label="imagen_logo_cmapa"
                    className="img_logo_alianza"
                    src={cmapa}
                    alt="cmapa_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    loading="lazy"
                    aria-label="imagen_logo_jlapas"
                    className="img_logo_alianza"
                    src={jlapas}
                    alt="jlapas_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_siamm"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={siamm}
                    alt="siamm_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_jumapaa"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={jumapaa}
                    alt="jumapaa_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_smapau"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={smapau}
                    alt="smapau_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_japasp"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={japasp}
                    alt="japasp_logo"
                  />
                </Col>

                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_comapa"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={comapa}
                    alt="comapa_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_ooapas"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={ooapas}
                    alt="ooapas_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_simapas"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={simapas}
                    alt="simapas_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_sapasma"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={sapasma}
                    alt="sapasma_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_sapa"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={sapa}
                    alt="sapa_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_japac"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={japac}
                    alt="japac_logo"
                  />
                </Col>
                <Col xs={8} md={3} lg={3}>
                  <img
                    aria-label="imagen_logo_jmapa"
                    loading="lazy"
                    className="img_logo_alianza"
                    src={jmapa}
                    alt="jmapa_logo"
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row justify={"center"}></Row>
            </Col>
          </Row>
        </div>

        {/* 
          Contenedor Contador de transacciones
        */}

        <div className="transaction_container">
          <Row justify={"center"} align={"middle"}>
            <Col xs={20} md={16} lg={24} style={{ marginTop: 20 }}>
              {transaction != undefined ? (
                <div className="button_transaction">
                  + {transaction.toLocaleString("en-US")}
                </div>
              ) : null}
            </Col>

            <Col
              xs={20}
              md={20}
              lg={20}
              style={{
                color: "#FFFFFF",
                fontFamily: "Avenir",
                letterSpacing: 6,
                fontSize: "xx-large",
                marginTop: 30,
              }}
            >
              DE TRANSACCIONES AL AÑO
            </Col>

            <Col
              xs={24}
              md={24}
              lg={24}
              style={{ color: "#FFFFFF", fontFamily: "Avenir", marginTop: 20 }}
            ></Col>
          </Row>
        </div>

        {/* 
            Carrousel con los productos de la empresa
          */}
        <div id="productos" className="product_container">
          <Row>
            <Col
              xs={24}
              md={24}
              lg={24}
              style={{
                color: "#FFFFFF",
                fontFamily: "Avenir",
                letterSpacing: 6,
                fontSize: "xx-large",
                marginTop: 50,
              }}
            >
              PRODUCTOS
            </Col>
            <Col>
              <Row justify={"center"}>
                <Col xs={24} md={24} lg={24}>
                  <Slider
                    effect="fade"
                    autoplay
                    autoplaySpeed={1900}
                    lazyLoad={true}
                    dots={true}
                    centerMode={true}
                    slidesToShow={3}
                    slidesToScroll={1}
                    arrows={false}
                    className="slider variable-width"
                    variableWidth={true}
                    draggable={false}
                    infinite
                    responsive={[
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                          infinite: true,
                          dots: true,
                        },
                      },
                      {
                        breakpoint: 600,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                          initialSlide: 2,
                        },
                      },
                      {
                        breakpoint: 480,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                        },
                      },
                      {
                        breakpoint: 200,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                        },
                      },
                    ]}
                  >
                    {/* 
                      Es el bueno // slaugther
                    */}

                    {/* <div style={{ width: 600 }}>
  <div className="slider_container">
    <div
      style={{
        height: "80%",
        width: "100%",
        display: "flex",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flex: 1,
        }}
      >
        <div className="container_name_slider">
          <p
            style={{
              transform: "rotate(180deg)",
              writingMode: "vertical-lr",
              color: "white",
            }}
          >
            {producto.nombre}
          </p>
        </div>
        <div className="container_img_slider">
          <img loading="lazy"
            src={producto.foto}
            alt={`${producto.nombre}_foto`}
          />
        </div>
      </div>
    </div>
    <div className="container_btn_slider">
      <button
        className="button_slide"
        onClick={() => verMasInfo(producto)}
      >
        Más información
      </button>
      <img loading="lazy"
        src={producto.logo}
        alt={`${producto.nombre}_logo`}
        className="img_logo"
      />
    </div>
  </div>
</div> */}
                    {productos.map((producto) => (
                      <div className="slider_container" key={producto.nombre}>
                        <div className="card_slider">
                          <div className="container_name_slider">
                            <p
                              style={{
                                transform: "rotate(180deg)",
                                writingMode: "vertical-lr",
                                color: "white",
                              }}
                            >
                              {producto.nombre}
                            </p>
                          </div>
                          <div className="container_img_slider">
                            <img
                              aria-label={`imagen_producto_${producto.nombre}`}
                              loading="lazy"
                              src={producto.foto}
                              alt={`${producto.nombre}_foto`}
                              style={{ width: "50%" }}
                            />
                          </div>
                          <div className="container_btn_slider">
                            <button
                              className="button_slide"
                              onClick={() => verMasInfo(producto)}
                            >
                              Más información
                            </button>
                            <div className="div_logo_productos">
                              <img
                                aria-label={`imagen_logo_${producto.nombre}`}
                                loading="lazy"
                                src={producto.logo}
                                alt={`${producto.nombre}_logo`}
                                className="img_logo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* 
          Div Facilitamos la vida
        */}

        <div className="easily_container">
          <Row justify={"center"} align={"middle"}>
            <Col xs={18} md={24} lg={24}>
              <div
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Avenir",
                  letterSpacing: 1,
                  fontSize: "xxx-large",
                }}
              >
                <p style={{ lineHeight: -20 }}>
                  Facilitamos la vida <br />
                  de <b style={{ color: "#96ECFB" }}>millones</b> de personas
                </p>
              </div>
            </Col>
            <Col
              xs={24}
              md={24}
              lg={24}
              style={{
                color: "#FFFFFF",
                fontFamily: "Avenir",
                letterSpacing: 1,
                fontSize: "x-large",
              }}
            >
              Más de 30 años de experiencia
            </Col>
          </Row>
        </div>
        {/* 
          Div valores
        */}

        <div className="valores_container">
          <Row justify={"center"}>
            <Col xs={24} md={12} lg={12} className="m4">
              <div className="text_white" style={{ marginRight: "20%" }}>
                <img
                  aria-label="imagen_logo_agua"
                  loading="lazy"
                  alt="img_agua"
                  src={logo_agua}
                  style={{ width: 200, height: "auto" }}
                />
                <p>
                  <b>Especialización</b> y <b>Enfoque</b>
                  <br />
                  total a los organismos de Agua
                  <br />
                  potable.
                </p>
              </div>
            </Col>
            <Col xs={24} md={12} lg={12} className="m4">
              <div className="text_white" style={{ marginLeft: "20%" }}>
                <img
                  aria-label="imagen_logo_escudo"
                  loading="lazy"
                  alt="img_escudo"
                  src={logo_escudo}
                  style={{ width: 200, height: "auto" }}
                />
                <p>
                  Personal eficiente que entiende sus
                  <br />
                  retos, necesidades y la resistencia al
                  <br />
                  cambio en los organismos.
                </p>
              </div>
            </Col>
          </Row>
          <div className="empatica_logo_container">
            <img
              aria-label="imagen_logo_empatica"
              loading="lazy"
              alt="imagen_empatica"
              src={logo_e}
              style={{ width: 200, height: "auto" }}
            />
          </div>
          <Row justify={"center"} style={{ marginTop: "8%" }}>
            <Col xs={24} md={12} lg={12}>
              <div className="text_white">
                <img
                  aria-label="imagen_logo_circuito"
                  loading="lazy"
                  alt="img_circuito"
                  src={logo_circuito}
                  style={{ width: 200, height: "auto" }}
                />
                <p>
                  Desarrollo de tecnología, aplicaciones y<br />
                  herramientasempáticas de bajo costo.
                </p>
              </div>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className="text_white">
                <img
                  aria-label="imagen_logo_tiempo"
                  loading="lazy"
                  alt="img_tiempo"
                  src={logo_tiempo}
                  style={{ width: 200, height: "auto" }}
                />
                <p>
                  Bajo tiempo de implementación. <br /> Alto impacto en su
                  eficiencia
                  <br />
                  operativa sin letras chiquitas.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        {/*  
          Valores Container
        */}
        <div className="valores_container_mobile">
          <Row justify={"center"}>
            <Col xs={20}>
              <img
                aria-label="imagen_logo_empatica"
                loading="lazy"
                alt="imagen_empatica"
                src={logo_e}
                style={{ width: 200, height: "auto" }}
              />
            </Col>
            <Col xs={20} style={{ marginTop: "10%" }}>
              <div className="text_white">
                <img
                  aria-label="imagen_logo_agua"
                  loading="lazy"
                  alt="img_agua"
                  src={logo_agua}
                  style={{ width: 200, height: "auto" }}
                />
                <p>
                  <b>Especialización</b> y <b>Enfoque</b>
                  <br />
                  total a los organismos de Agua
                  <br />
                  potable.
                </p>
              </div>
            </Col>
            <Col xs={20}>
              <div className="text_white">
                <img
                  aria-label="imagen_logo_escudo"
                  loading="lazy"
                  alt="img_escudo"
                  src={logo_escudo}
                  style={{ width: 200, height: "auto" }}
                />
                <p>
                  Personal eficiente que entiende sus
                  <br />
                  retos, necesidades y la resistencia al
                  <br />
                  cambio en los organismos.
                </p>
              </div>
            </Col>
            <Col xs={20}>
              <div className="text_white">
                <img
                  aria-label="imagen_logo_circuito"
                  loading="lazy"
                  alt="img_circuito"
                  src={logo_circuito}
                  style={{ width: 200, height: "auto" }}
                />
                <p>
                  Desarrollo de tecnología, aplicaciones y herramientas
                  empáticas de bajo costo.
                </p>
              </div>
            </Col>
            <Col xs={20}>
              <div className="text_white">
                <img
                  aria-label="imagen_logo_tiempo"
                  loading="lazy"
                  alt="img_tiempo"
                  src={logo_tiempo}
                  style={{ width: 200, height: "auto" }}
                />
                <p>
                  Bajo tiempo de implementación. Alto impacto en su eficiencia
                  operativa sin letras chiquitas.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        {/* 
          Div "Contacto"
        */}
        <div id="contacto" className="container_contacto">
          <Row justify={"center"} align={"middle"}>
            <Col
              md={12}
              lg={12}
              style={{
                backgroundImage: "url('" + bg_contacto1 + "')",
                backgroundSize: "cover",
                width: "100%",
                marginTop: -10,
                height: "70vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <Row align={"middle"} justify={"center"}>
                  <p style={{ fontSize: 20, color: "white" }}>
                    Empática Soluciones, un producto de CORPASI S.A.
                  </p>
                </Row>

                <Row align={"middle"} justify={"center"}>
                  <ul>
                    <li>
                      <Link to={"/altasistec"} style={{ fontSize: 20 }}>
                        Alta en SISTEC
                      </Link>
                    </li>
                    <li>
                      <Link to={"/avisosprivacidad"} style={{ fontSize: 20 }}>
                        Avisos de privacidad
                      </Link>
                    </li>
                    <li>
                      <Link to={"/garantias"} style={{ fontSize: 20 }}>
                        Garantias
                      </Link>
                    </li>
                  </ul>
                  {/* <Form form={form} layout="vertical" style={{ width: "100%" }}>
                    <Form.Item>
                      <Select
                        placeholder={
                          <b style={{ color: "black" }}>
                            Avisos de privacidad y reservas
                          </b>
                        }
                        onChange={ChangePolizas}
                      >
                        <Select.Option value={poliza_cajeros} key={1}>
                          Poliza de Garantia Cajeros QuickPay
                        </Select.Option>
                        <Select.Option value={reservas_limitaciones} key={2}>
                          Reservas y Limitaciones en formato de cotización
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Form> */}
                </Row>
              </div>
            </Col>
            <Col md={12} lg={12}>
              <div
                style={{
                  backgroundImage: "url('" + bg_contacto2 + "')",
                  backgroundSize: "cover",
                  width: "100%",
                  marginTop: -10,
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Row align={"middle"} justify={"center"}>
                  <Col
                    xs={24}
                    sm={24}
                    lg={24}
                    style={{
                      marginTop: 50,
                      color: "white",
                      fontFamily: "Avenir",
                    }}
                  >
                    <p style={{ fontSize: 25 }}>C O N T A C T O</p>
                    <img
                      aria-label="imagen_logo_telefono"
                      loading="lazy"
                      alt="contact_phone"
                      src={logo_phone}
                      style={{
                        width: 50,
                        height: "auto",
                      }}
                    />
                    <br />
                    <a
                      href="tel:4433333935"
                      style={{ fontSize: 20, color: "white" }}
                    >
                      (443) 333-3935
                    </a>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    lg={24}
                    style={{ color: "white", fontFamily: "Avenir" }}
                  >
                    <img
                      aria-label="imagen_logo_contacto_correo"
                      loading="lazy"
                      alt="contact_mail"
                      src={logo_mail}
                      style={{
                        width: 50,
                        height: "auto",
                      }}
                    />
                    <br />
                    <a
                      href="mailto:contacto@empatica.mx"
                      style={{ fontSize: 20, color: "white" }}
                    >
                      contacto@empatica.mx
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        {/* 
          Div contacto mobile
        */}
        <div id="contacto" className="container_contacto_mobile">
          <Row justify={"center"} align={"middle"}>
            <Col xs={24} md={12} lg={12}>
              <div>
                <Row align={"middle"} justify={"center"}>
                  <Col
                    xs={24}
                    sm={24}
                    lg={24}
                    style={{
                      marginTop: 50,
                      color: "white",
                      fontFamily: "Avenir",
                    }}
                  >
                    <p style={{ fontSize: 25 }}>C O N T A C T O</p>
                    <img
                      aria-label="imagen_contacto_telefono"
                      loading="lazy"
                      alt="contact_phone"
                      src={logo_phone}
                      style={{
                        width: 50,
                        height: "auto",
                      }}
                    />
                    <br />
                    <a style={{ color: "white", fontSize: 20 }}>
                      (443) 333-3935
                    </a>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    lg={24}
                    style={{ color: "white", fontFamily: "Avenir" }}
                  >
                    <img
                      aria-label="imagen_logo_contacto_correo"
                      loading="lazy"
                      alt="contact_mail"
                      src={logo_mail}
                      style={{
                        width: 50,
                        height: "auto",
                      }}
                    />
                    <br />
                    <a style={{ color: "white", fontSize: 20 }}>
                      contacto@empatica.mx
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        {currentProduct !== undefined ? ( // eslint-disable-next-line
          <ConfigProvider
            modal={{
              classNames,
              styles: modalStyles,
            }}
          >
            <Modal
              // title={`${currentProduct.nombre}`}
              open={modalInfo}
              centered={true}
              closeIcon={
                <Button className="btn_close_modal" icon={<CloseOutlined />} />
              }
              onOk={() => setModalInfo(false)}
              onCancel={() => {
                setModalInfo(false);
                setCurrentProduct(undefined);
              }}
              footer={null}
              width={600}
            >
              {currentProduct.sub_productos != null ? (
                <div>
                  <Button
                    onClick={previous}
                    className="btn_slick_left"
                    icon={
                      <IconChevronCompactLeft className="slick_btn_icons" />
                    }
                  />
                  <Slider
                    effect="fade"
                    // autoplay
                    // autoplaySpeed={1900}
                    lazyLoad={true}
                    centerMode={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                    ref={(c) => (slider.current = c)}
                    // className="slider variable-width"
                    variableWidth={true}
                    responsive={[
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          infinite: true,
                          dots: false,
                          arrows: true,
                        },
                      },
                      {
                        breakpoint: 600,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          initialSlide: 2,
                          infinite: true,
                          dots: false,
                          arrows: true,
                        },
                      },
                      {
                        breakpoint: 480,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          infinite: true,
                          dots: false,
                          arrows: true,
                        },
                      },
                    ]}
                  >
                    {currentProduct.sub_productos.map((producto) => (
                      <div
                        key={producto.nombre}
                        className="modal_slider_container"
                      >
                        <div
                          className="modal_card_slider"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="modal_description"
                            style={{ right: "10%" }}
                          >
                            <div className="modal_img_producto">
                              <img
                                aria-label={`imagen_modal_${producto.nombre}`}
                                loading="lazy"
                                src={producto.foto}
                                alt={`${producto.nombre}_foto`}
                                style={{ height: "100%" }}
                              />
                            </div>
                            <div className="modal_info_producto text_white">
                              <div style={{ height: "100%" }}>
                                <p>{producto.titulo}</p>
                                <p>{producto.informacion}</p>

                                {currentProduct.aviso_privacidad ? (
                                  <div style={{ height: 100 }}>
                                    <a
                                      href={currentProduct.aviso_privacidad}
                                      target="_blank"
                                      style={{
                                        display: "inline-block",
                                        borderRadius: 15,
                                        margin: 40,
                                        backgroundColor: "#191919",
                                        boxShadow:
                                          "rgba(255, 255, 255, 0.7) 9px 6px 20px 2px inset, rgba(70, 70, 70, 0.1) 10px -10px 15px" /*  este es el bueno */,
                                        color: "#ffffff",
                                        fontSize: 18,
                                        fontWeight: 2,
                                        width: 200,
                                        height: 40,
                                        padding: "10,10,50,60",
                                      }}
                                    >
                                      Brochure
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  <Button
                    onClick={next}
                    icon={
                      <IconChevronCompactRight className="slick_btn_icons" />
                    }
                    className="btn_slick_right"
                  />
                </div>
              ) : (
                <div className="modal_description">
                  <div className="modal_img_producto">
                    <img
                      aria-label={`imagen_modal_${currentProduct.nombre}`}
                      loading="lazy"
                      src={currentProduct.foto}
                      alt={`${currentProduct.nombre}_foto`}
                      style={{ height: "100%" }}
                    />
                  </div>
                  <div className="modal_info_producto text_white">
                    <div style={{ height: "100%" }}>
                      <p>{currentProduct.titulo}</p>
                      <p>{currentProduct.informacion}</p>
                    </div>
                  </div>
                </div>
              )}
            </Modal>
          </ConfigProvider>
        ) : null}
      </Content>
    </Layout>
  );
};
